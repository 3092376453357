// PageTitle.jsx
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AppPageTitle = ({ preTitle, title, subtitle }) => (
  <Row className="mb-4">
    <Col>
      {subtitle && (
        <h4>
          <span className="fw-bold mr-2">{preTitle} </span>
          {title} ({subtitle})
        </h4>
      )}
      {!subtitle && (
        <h4>
          <span className="fw-bold mr-2">{preTitle} </span>
          {title}
        </h4>
      )}
    </Col>
  </Row>
);

export default AppPageTitle;
