import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';
import InstaHearLogoSrc from '../instahear-logo.png';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const AdminSessionDetailPage = () => {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [messageColor, setMessageColor] = useState('');

  // Fetch session data function, wrapped in useCallback to ensure stable reference
  const fetchSessionData = useCallback(async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/sessions/admin/${sessionId}`,
        {
          credentials: 'include',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch session data');
      }
      const data = await response.json();
      setSessionData(data);
      setSelectedStatus(data.result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [sessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setIsStatusChanged(true);
  };

  const handleUpdateStatus = async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/sessions/admin/${sessionId}/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            sessionId,
            result: selectedStatus,
          }),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setStatusMessage(data.message);
        setMessageColor('text-success');
        setIsStatusChanged(false);
        fetchSessionData(); // Refresh session data on success
      } else {
        setStatusMessage(data.message || 'Failed to update status');
        setMessageColor('text-danger');
      }
    } catch (error) {
      setStatusMessage('Failed to update status');
      setMessageColor('text-danger');
    }
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };
    return new Date(dateTimeString).toLocaleString('en-US', options);
  };

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = ((ms % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!sessionData) {
    return <div>No session data available.</div>;
  }

  return (
    <div className="container my-5">
      <JobCompanyLogo src={InstaHearLogoSrc} alt="InstaHear Logo" />

      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-3"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Applicant Information
          </h5>
          <p>
            <strong>Name:</strong> {sessionData.firstName}{' '}
            {sessionData.lastName}
          </p>
          <div className="d-flex justify-content-between">
            <p>
              <strong>Phone:</strong> {sessionData.phone}
            </p>
          </div>
        </div>
      </div>

      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-3"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Interview Summary
          </h5>
          <p>
            <strong>Job:</strong> {sessionData.jobTitle}
          </p>
          <p>
            <strong>Start Time:</strong> {formatDateTime(sessionData.startTime)}
          </p>
          <p>
            <strong>Duration:</strong> {formatDuration(sessionData.duration)}
          </p>

          {/* Status Dropdown */}
          <div className="mb-3">
            <strong>Result: </strong>
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="form-select d-inline w-auto ms-2"
            >
              <option value="KNOCKOUT_FAILED">KNOCKOUT_FAILED</option>
              <option value="INCOMPLETE">INCOMPLETE</option>
              <option value="PASSED">PASSED</option>
              <option value="FAILED">FAILED</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
            </select>
          </div>

          {sessionData.result === 'FAILED' && sessionData.failedReasons && (
            <div className="mt-3">
              <strong>Failed Reasons:</strong>
              <ul>
                {sessionData.failedReasons.failedSuccessCriteria?.map(
                  (reason, index) => (
                    <li key={index} style={{ fontWeight: 'normal' }}>
                      {reason}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          {sessionData.result === 'INCOMPLETE' &&
            sessionData.incompleteReasons && (
              <div className="mt-3">
                <strong>Incomplete Reasons:</strong>
                <ul>
                  {sessionData.incompleteReasons.omittedSuccessCriteria?.map(
                    (reason, index) => (
                      <li key={index} style={{ fontWeight: 'normal' }}>
                        {reason}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
        </div>
      </div>

      {isStatusChanged && (
        <div className="text-center my-4">
          <button className="btn btn-danger" onClick={handleUpdateStatus}>
            Update Status
          </button>
        </div>
      )}

      {statusMessage && (
        <div className={`text-center my-3 ${messageColor}`}>
          {statusMessage}
        </div>
      )}

      <footer className="text-center mt-5">
        2024 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default AdminSessionDetailPage;
