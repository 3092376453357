import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import TermsCheckbox from '../components/jobs/TermsCheckbox';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';
import InstaHearLogoSrc from '../instahear-logo.png';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const AdminDemoPage = () => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobCompanyLogo, setJobCompanyLogo] = useState(InstaHearLogoSrc);
  const [jobOptions, setJobOptions] = useState([]);
  const [engageSmsEmail, setEngageSmsEmail] = useState(false); // New state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Submit Application');
  const [isChecked, setIsChecked] = useState(false); // Terms checkbox state
  const [showCheckboxError, setShowCheckboxError] = useState(false); // Validation error for terms checkbox

  // Modal State
  const [showModal, setShowModal] = useState(false);
  const [interviewNowUrl, setInterviewNowUrl] = useState('');
  const [engageStatus, setEngageStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const firstNameFromUrl = params.get('firstName');
    const lastNameFromUrl = params.get('lastName');
    const phoneFromUrl = params.get('phone');
    const emailFromUrl = params.get('email');
    const jobIdFromUrl = params.get('jobId');
    const acceptTermsFromUrl = params.get('acceptTerms') === 'true';

    if (firstNameFromUrl) setFirstName(firstNameFromUrl);
    if (lastNameFromUrl) setLastName(lastNameFromUrl);
    if (phoneFromUrl) setPhone(phoneFromUrl);
    if (emailFromUrl) setEmail(emailFromUrl);
    if (jobIdFromUrl) setJobId(jobIdFromUrl);
    if (acceptTermsFromUrl) setIsChecked(true);
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/admin/demo-jobs`, {
          credentials: 'include',
        });
        const data = await response.json();
        setJobOptions(data.demoJobs || []);
      } catch (error) {
        console.error('Error fetching job options:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate if terms checkbox is required and not selected
    if (engageSmsEmail && !isChecked) {
      setShowCheckboxError(true);
      return;
    }

    setIsSubmitting(true);

    const formData = {
      channel: 'Demo',
      lastName,
      firstName,
      phone,
      email,
      jobId,
      engageSmsEmail, // Include engageSmsEmail in payload
    };

    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/admin/application/demo`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setInterviewNowUrl(data.interviewNowUrl);
        setEngageStatus(data.engageSmsEmail);
        setShowModal(true); // Show modal after successful submission
        setHasSubmitted(true);
        setButtonText(`Application ${engageSmsEmail ? 'Sent' : 'Created'}!`);
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container my-5">
      <JobCompanyLogo src={jobCompanyLogo} alt="Company Logo" />

      <div className="form-container bg-white px-4 py-5 rounded shadow">
        <p className="pb-3 text-center subtitle-demo-form">
          Fill out your Applicant's info below to generate an
          <br /> Interview Now link.
        </p>

        <form onSubmit={handleSubmit}>
          {/* First Name and Last Name */}
          <div className="row">
            <div className="form-group col-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="form-group col-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Phone and Email */}
          <div className="row">
            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                className="form-control"
                value={phone}
                onChange={(e) => {
                  const onlyDigits = e.target.value.replace(/\D/g, '');
                  setPhone(onlyDigits);
                }}
                maxLength="10"
                minLength="10"
              />
            </div>

            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          {/* Job Position */}
          <div className="form-group mb-4">
            <label htmlFor="jobId" className="form-label">
              Job Position:
            </label>
            <select
              id="jobId"
              className="form-select"
              value={jobId}
              onChange={(e) => {
                setJobId(e.target.value);
                const selectedOption = e.target.selectedOptions[0];
                const logo = selectedOption.getAttribute('jobcompanylogo');
                setJobCompanyLogo(logo || InstaHearLogoSrc); // Fallback to default if logo is not set
              }}
              required
            >
              <option value="">Select a Job</option>
              {jobOptions.map((job) => (
                <option
                  key={job.jobId}
                  value={job.jobId}
                  jobcompanylogo={job.jobCompanyLogo}
                >
                  {job.jobTitle} ({job.jobCompanyName})
                </option>
              ))}
            </select>
          </div>

          {/* Engage via SMS and Email */}
          {phone && phone !== '' && (
            <div className="form-group mb-4">
              <label className="form-label">Engagement Settings:</label>
              <div>
                <input
                  type="checkbox"
                  checked={engageSmsEmail}
                  onChange={(e) => setEngageSmsEmail(e.target.checked)}
                />
                <span className="ms-2">
                  Send Interview Now link via SMS and Email
                </span>
              </div>
            </div>
          )}

          {/* Terms of Service */}
          {engageSmsEmail && (
            <TermsCheckbox
              mode="admin-demo"
              isChecked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                setShowCheckboxError(false);
              }}
              showError={showCheckboxError}
            />
          )}

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-3"
            disabled={isSubmitting || hasSubmitted}
          >
            {buttonText}
          </button>
        </form>
      </div>

      {/* Information Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100 fw-bold">
            Application Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Interview Now Demo Link:</p>
          <div className="d-flex justify-content-center align-items-center">
            <a
              href={interviewNowUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2"
            >
              {interviewNowUrl}
            </a>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(interviewNowUrl);
                alert('Interview Now link copied to clipboard!');
              }}
              className="ms-2"
            >
              📋
            </Button>
          </div>
          <br />
          <br />
          {engageStatus ? (
            <p>Applicant has been engaged via SMS and Email.</p>
          ) : (
            <p>🙋 Note, Applicant has not been engaged via SMS and Email.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <footer className="text-center mt-5">
        2024 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default AdminDemoPage;
