import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './instahear-logo-white.png';
import filterIcon from './filter-application-icon.png';
import analyticsIcon from './analytics-icon.png';
import integrationIcon from './ats-integration-icon.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './HomePage.css'; // Import the custom CSS
import './HomePageNavbar.css';

const Homepage = () => {
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  const navbarCollapseRef = useRef(null); // Create a ref for the collapsible navbar

  const handleNavLinkClick = () => {
    if (navbarCollapseRef.current) {
      const collapse = new window.bootstrap.Collapse(navbarCollapseRef.current);
      collapse.hide(); // Collapse the navbar when a link is clicked
    }
  };

  return (
    <div className="homepage">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container-fluid desktop-container">
          {' '}
          {/* New container class for wider desktop layout */}
          <a href="/">
            <img src={logo} alt="InstaHear Logo" height={50} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse bg-dark w-100"
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {' '}
              {/* ms-auto for right-aligned navigation links */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#about-us"
                  onClick={() => {
                    handleNavLinkClick();
                    scrollToSection('about');
                  }}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact-us"
                  onClick={() => {
                    handleNavLinkClick();
                    scrollToSection('contact');
                  }}
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/login">
                  Login
                </a>
              </li>
            </ul>
            <button
              className="btn btn-primary cta-btn ms-lg-3"
              onClick={() => navigate('/demo')}
            >
              See a Demo
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="hero-container">
        <section className="hero text-center d-flex flex-column justify-content-center align-items-center">
          <div className="container desktop-container">
            {' '}
            {/* New container class for wider desktop layout */}
            <h1 className="hero-title">
              Grow your business with 24/7 applicant screening
            </h1>
            <p className="hero-subtitle">
              With InstaHear, you can easily offer your applicants the
              opportunity to interview within minutes of applying to your jobs.
            </p>
            <button className="btn hero-btn" onClick={() => navigate('/demo')}>
              See a Demo
            </button>
          </div>
        </section>
        {/* About Us Section */}
        <section id="about" className="about-us py-5">
          <div className="container desktop-container">
            <h2 className="mb-4">About InstaHear</h2>
            <p>
              InstaHear is revolutionizing the applicant screening process. Our
              platform offers advanced features that streamline your hiring
              process, helping you find the perfect candidates faster and more
              efficiently.
            </p>
          </div>
        </section>
        {/* Features Section */}
        <section className="features py-5 bg-white">
          <div className="container desktop-container">
            <h2 className="mb-4">Our Key Features</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="feature-box">
                  <img
                    src={filterIcon}
                    alt="Feature 1"
                    className="mb-3 homepage-icon"
                  />
                  <h4>Automated Screening</h4>
                  <p>
                    Instantly screen applicants and match them with your
                    requirements.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-box">
                  <img
                    src={analyticsIcon}
                    alt="Feature 2"
                    className="mb-3 homepage-icon"
                  />
                  <h4>Smart Insights</h4>
                  <p>
                    Get actionable insights to improve your hiring process and
                    streamline communication.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-box">
                  <img
                    src={integrationIcon}
                    alt="Feature 3"
                    className="mb-3 homepage-icon"
                  />
                  <h4>Easy ATS Integration</h4>
                  <p>
                    Connect with your Applicant Tracking System to automatically
                    update your applicants.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Us Section */}
        <section id="contact" className="contact-us py-5 bg-light">
          <div className="container desktop-container">
            <h2 className="mb-4">Contact Us</h2>
            <form className="w-75 mx-auto">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  placeholder="Your Message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
          </div>
        </section>
      </div>
      {/* Footer */}
      <footer className="footer bg-dark text-white text-center py-4">
        <p>&copy; 2024 InstaHear LLC • All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default Homepage;
