import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // React Router hooks
import 'bootstrap/dist/css/bootstrap.min.css';
import ConversationMessages from '../components/app/ConversationMessages';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BACKEND_API_URL =
  process.env.REACT_APP_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://api.instahear.ai';

const ConversationMessagesPage = () => {
  const [messages, setMessages] = useState([]);
  const [application, setApplication] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);

  // Extract conversationId from the URL path
  const { conversationId } = useParams();

  // Extract applicationId from query params
  const { search } = useLocation();
  const applicationId = new URLSearchParams(search).get('applicationId');

  // Fetch messages on page load
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(
          `${BACKEND_API_URL}/api/conversations/${conversationId}/messages?applicationId=${applicationId}`,
          {
            method: 'GET',
            credentials: 'include', // Include credentials if required
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch messages: ${response.statusText}`);
        }

        const data = await response.json();
        setMessages(data.messages);
        setApplication(data.application);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [conversationId, applicationId]);

  // Handle sending a new message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/conversations/${conversationId}/messages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messageContent: newMessage, applicationId }),
          credentials: 'include', // Include credentials if required
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }

      const data = await response.json();

      // Check for success or error based on response data
      if (data.message && !data.message.errorCode) {
        // Success: Display success toastr
        toast.success('Success: SMS message created and queued for delivery.');
      } else {
        // Error: Display error toastr
        const errorMessage = data.message?.errorMessage || 'Unknown error.';
        toast.error(`Error: SMS message could not be created. ${errorMessage}`);
      }

      // Update state with the new message
      setMessages((prevMessages) => [...prevMessages, data.message]);
      setNewMessage(''); // Clear input field
    } catch (error) {
      console.error('Error sending message:', error);

      // Display error toastr for request failure
      toast.error('Error: SMS message could not be created. Please try again.');
    }
  };

  if (loading) return <div className="loading">Loading messages...</div>;

  return (
    <>
      <ConversationMessages
        application={application}
        messages={messages}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleSendMessage={handleSendMessage}
      ></ConversationMessages>
      <ToastContainer />
    </>
  );
};

export default ConversationMessagesPage;
