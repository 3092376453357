import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import ThankYouPage from './ThankYouPage';
import DemoPage from './DemoPage'; // Import the new DemoPage
import AdminDemoPage from './appPages/AdminDemoPage';
import './App.css'; // Import the CSS styles
import ApplicationNotFoundPage from './ApplicationNotFoundPage';
import JobNotAvailablePage from './JobNotAvailablePage';
import Homepage from './HomePage';
import SessionDetailPage from './SessionDetailPage';
import AdminSessionDetailPage from './appPages/AdminSessionDetailPage';
import CompanyJobSessionsPage from './appPages/CompanyJobSessionsPage';
import LoginPage from './appPages/LoginPage';
import VerifyAuthTokenPage from './appPages/VerifyAuthTokenPage';
import PilotAnalyticsPage from './appPages/PilotAnalyticsPage';
import CompanyJobsPage from './appPages/CompanyJobsPage';
import CompanyAssessmentsPage from './appPages/CompanyAssessmentsPage';
import InterviewNowPage from './InterviewNowPage';
import PilotNavbarWrapper from './appPages/PilotNavbarWrapper'; // Import the wrapper
import CompanyJobDetailsPage from './appPages/CompanyJobDetailsPage';
import CompanyAssessmentDetailsPage from './appPages/CompanyAssessmentDetailsPage';
import LogoutPage from './appPages/Logout';
import { AuthProvider } from './appPages/AuthProvider';
import ProtectedRoutes from './appPages/ProtectedRoutes';
import AdminCompanyJobApplicationsPage from './appPages/AdminCompanyJobApplicationsPage';
import ConversationMessagesPage from './appPages/ConversationMessagesPage';
import BookAMeetingPage from './BookAMeetingPage';

const App = () => {
  return (
    <Router>
      <div>
        {/* Define the routes here */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/interview/:applicationShortCode"
            element={<InterviewNowPage />}
          />
          <Route
            path="/public/sessions/:sessionId"
            element={<SessionDetailPage />}
          />

          <Route path="/thank-you/jobs/:jobId" element={<ThankYouPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/book-a-meeting" element={<BookAMeetingPage />} />

          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/application-not-found"
            element={<ApplicationNotFoundPage />}
          />
          <Route path="/job-not-available" element={<JobNotAvailablePage />} />

          <Route path="/auth/verify" element={<VerifyAuthTokenPage />} />

          {/* Logout Route */}
          <Route path="/logout" element={<LogoutPage />} />

          {/* Authenticated Routes */}
          <Route
            element={
              <AuthProvider>
                {' '}
                <ProtectedRoutes />
              </AuthProvider>
            }
          >
            <Route
              path="/admin/sessions/:sessionId"
              element={
                <PilotNavbarWrapper>
                  <AdminSessionDetailPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/sessions/jobs/:jobId"
              element={
                <PilotNavbarWrapper>
                  <CompanyJobSessionsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/sessions"
              element={
                <PilotNavbarWrapper>
                  <CompanyJobSessionsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/admin/applications"
              element={
                <PilotNavbarWrapper>
                  <AdminCompanyJobApplicationsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/admin/applications/jobs/:jobId"
              element={
                <PilotNavbarWrapper>
                  <AdminCompanyJobApplicationsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/analytics"
              element={
                <PilotNavbarWrapper>
                  <PilotAnalyticsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/analytics/jobs/:jobId"
              element={
                <PilotNavbarWrapper>
                  <PilotAnalyticsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/jobs"
              element={
                <PilotNavbarWrapper>
                  <CompanyJobsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/call-scripts"
              element={
                <PilotNavbarWrapper>
                  <CompanyAssessmentsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/jobs/:jobId/edit"
              element={
                <PilotNavbarWrapper>
                  <CompanyJobDetailsPage />
                </PilotNavbarWrapper>
              }
            />
            <Route
              path="/call-scripts/:assessmentId/edit"
              element={
                <PilotNavbarWrapper>
                  <CompanyAssessmentDetailsPage mode="edit" />
                </PilotNavbarWrapper>
              }
            />

            <Route
              path="/call-scripts/new"
              element={
                <PilotNavbarWrapper>
                  <CompanyAssessmentDetailsPage mode="create" />
                </PilotNavbarWrapper>
              }
            />

            <Route
              path="/admin/demo"
              element={
                <PilotNavbarWrapper>
                  <AdminDemoPage />
                </PilotNavbarWrapper>
              }
            />

            <Route
              path="/conversation/messages/:conversationId"
              element={
                <PilotNavbarWrapper>
                  <ConversationMessagesPage />
                </PilotNavbarWrapper>
              }
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
