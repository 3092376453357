import React from 'react';
import { useLocation } from 'react-router-dom';
import './ThankYouPage.css'; // Ensure that you have styling for card and shadow here
import JobCompanyLogo from './components/jobs/JobCompanyLogo';

const JobNotAvailablePage = () => {
  const location = useLocation();
  const error = location.state?.error || 'No additional information available.';
  const companyLogoURL =
    location.state?.companyLogoURL ||
    'https://storage.googleapis.com/instahear-production-clientapp-assets/company-logos/instahear-logo.png'; // Fallback to default logo

  return (
    <div className="thank-you-container">
      {/* TODO: Replace hardcoded Signal Security company logo*/}
      <div className="card-container">
        <JobCompanyLogo src={companyLogoURL} alt="Signal Logo" />
        <h1 className="thank-you-header">Job No Longer Available</h1>
        <p className="thank-you-subtitle">{error}</p>
      </div>
    </div>
  );
};

export default JobNotAvailablePage;
