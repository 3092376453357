import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';
import InstaHearLogoSrc from '../instahear-logo.png';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthToken = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/auth/check`, {
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          if (data.isAuthenticated) {
            navigate('/sessions'); // Redirect to homepage if authenticated
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        console.error('Error checking authentication:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthToken();
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/magic-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Check your email for a magic link to log in!');
        setAlertVariant('success'); // Green for success
      } else {
        const errorData = await response.json();
        const additionalMessage = errorData.message
          ? ` ${errorData.message}`
          : '';
        setMessage(`Failed to send magic link. ${additionalMessage}`);
        setAlertVariant('danger'); // Red for failure
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
      setAlertVariant('danger'); // Red for error
    }
  };

  return (
    !loading && (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '50vh' }}
      >
        <Card style={{ width: '100%', maxWidth: '400px' }}>
          <Card.Body>
            <JobCompanyLogo
              src={InstaHearLogoSrc}
              alt="Company Logo"
              width={'50%'}
            />
            <h3 className="text-center">Login</h3>
            {message && <Alert variant={alertVariant}>{message}</Alert>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button className="w-100 mt-3" type="submit">
                Send Magic Link
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    )
  );
};

export default LoginPage;
