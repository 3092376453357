import React from 'react';
import { useLocation } from 'react-router-dom';
import './ThankYouPage.css'; // Ensure that you have styling for card and shadow here
import JobCompanyLogo from './components/jobs/JobCompanyLogo';

const ThankYouPage = () => {
  const location = useLocation();
  const companyLogoURL =
    location.state?.companyLogoURL ||
    'https://storage.googleapis.com/instahear-production-clientapp-assets/company-logos/instahear-logo.png'; // Fallback to default logo
  const firstName = location.state?.firstName || null;

  return (
    <div className="thank-you-container">
      {/* Card-like container with drop shadow */}
      <div className="card-container">
        <JobCompanyLogo src={companyLogoURL} alt="Signal Logo" />
        <h1 className="thank-you-header">{`Thank you for your interest${
          firstName ? `, ${firstName}` : ''
        }`}</h1>
        <p className="thank-you-subtitle">
          We genuinely appreciate your effort, but unfortunately, this role
          isn’t the right fit at the moment.
        </p>
        <p className="thank-you-subtitle">
          Your time and interest are important to us, and we’re confident that
          the right opportunity is just around the corner. We wish you the very
          best in your job search and future endeavors!
        </p>
      </div>
    </div>
  );
};

export default ThankYouPage;
