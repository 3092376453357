import { useEffect, useRef } from 'react';
import { Container, Card } from 'react-bootstrap';
import { VariableSizeList as List } from 'react-window';
import AppPageTitle from './AppPageTitle';
import './ConversationMessages.css';

const ConversationMessages = ({
  application,
  messages, // Full messages array
  newMessage,
  setNewMessage,
  handleSendMessage,
}) => {
  const listRef = useRef(null);

  const formatMessageDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  // Scroll to bottom on initial load
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(messages.length - 1, 'end');
    }
  }, [messages]);

  // Dynamic height for messages (can vary per message)
  const getItemSize = (index) => {
    const message = messages[index];
    const baseHeight = 50; // Base height for a small message
    const lineCount = Math.ceil(message.body.length / 50); // Estimate lines of text
    return baseHeight + lineCount * 20; // Adjust for longer messages
  };

  // Render a single message
  const MessageRow = ({ index, style }) => {
    const message = messages[index];
    const isUserMessage = message.type !== 'END_USER';
    const isUserMessageDelivered =
      isUserMessage && message.twilioMessageStatus === 'delivered';

    return (
      <div
        style={style}
        className={`message-wrapper ${
          isUserMessage ? 'right-message' : 'left-message'
        }`}
      >
        <div
          className={`message-bubble ${
            isUserMessage ? 'blue-bubble' : 'gray-bubble'
          }`}
        >
          <p className="message-content">{message.body}</p>
        </div>
        <p
          className={`message-timestamp ${
            isUserMessage ? 'blue-bubble-timestamp' : 'gray-bubble-timestamp'
          }`}
        >
          {isUserMessage
            ? isUserMessageDelivered
              ? formatMessageDate(message.createdAt)
              : ''
            : formatMessageDate(message.createdAt)}
        </p>
      </div>
    );
  };

  return (
    <Container fluid className="conversation-container small-font">
      <AppPageTitle
        preTitle={application ? 'SMS Messages for: ' : ''}
        title={
          application
            ? `${application.firstName} ${application.lastName}`
            : 'Applications'
        }
        subtitle={application && application.phone}
      />
      <Card className="mt-3">
        <Card.Body className="p-0">
          <List
            className="messages-container"
            height={600} // Height of the visible container
            itemCount={messages.length} // Total messages
            itemSize={getItemSize} // Dynamic height for each row
            width="100%" // Full width
            ref={listRef} // Ref for scrolling
          >
            {MessageRow}
          </List>
        </Card.Body>
        <Card.Footer className="p-3 bg-light">
          <div className="message-input-container d-flex">
            <input
              type="text"
              className="message-input flex-grow-1"
              placeholder="Type a message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button
              className="message-send-button ms-2"
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default ConversationMessages;
