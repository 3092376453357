import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import TermsDisclaimer from './components/jobs/TermsDisclaimer';
import Subtitle from './components/jobs/Subtitle';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobCompanyLogo from './components/jobs/JobCompanyLogo';

export let BACKEND_API_URL = 'https://api.instahear.ai';
export let BACKEND_WEBSOCKET_URL = 'wss://api.instahear.ai/ws/';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
  BACKEND_WEBSOCKET_URL = 'ws://localhost:8080';
}

const InterviewNowPage = () => {
  const defaultCompanyLogoURL =
    'https://storage.googleapis.com/instahear-production-clientapp-assets/company-logos/instahear-logo.png';
  const { applicationShortCode } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [initialEmail, setInitialEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isPreview, setIsPreview] = useState(null);
  const [companyLogoURL, setCompanyLogoURL] = useState(null);
  const [applicationId, setApplicationId] = useState('');
  const [knockoutQuestions, setKnockoutQuestions] = useState([]);
  const [knockoutAnswers, setKnockoutAnswers] = useState([]);
  const [formError, setFormError] = useState(''); // State to track errors

  const [callButtonText, setCallButtonText] = useState('Interview Now');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [callId, setCallId] = useState(null); // Store callId
  const [callStatus, setCallStatus] = useState(''); // State for call status
  const [endedReason, setEndedReason] = useState(''); // State for call endedReason
  const [ws, setWs] = useState(null); // Store WebSocket connection

  const navigate = useNavigate();
  const location = useLocation();

  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    // Set isPreview to true if "isPreview=true" is in the URL
    setIsPreview(queryParams.get('isPreview') === 'true');
  }, [location]);

  useEffect(() => {
    const fetchApplicantData = async () => {
      // Check if applicationShortCode is missing or falsy, redirect to '/application-not-found'
      if (!applicationShortCode) {
        navigate('/application-not-found');
        return; // Exit early since no need to fetch data
      }

      try {
        let isPreviewQueryParam = '';
        if (isPreview) {
          isPreviewQueryParam = '?isPreview=true';
        }

        const response = await fetch(
          `${BACKEND_API_URL}/api/application/${applicationShortCode}${isPreviewQueryParam}`
        );

        // Handle the response
        const data = await response.json();

        // Check if response status is 404 and handle based on the error type
        if (!response.ok && response.status === 404) {
          if (data.type === 'application') {
            //setFormError(data.error);
            navigate('/application-not-found');
            return; // Exit early since no further processing is needed
          }

          if (data.type === 'job') {
            //setFormError(data.error);
            navigate('/job-not-available', {
              state: { error: data.error, companyLogoURL: data.companyLogoURL },
            });
            return; // Exit early since no further processing is needed
          }
        }

        setFirstName(data.firstName || '');
        setLastName(data.lastName || '');
        setPhoneNumber(data.phoneNumber || '');
        setInitialEmail(data.email || '');
        setEmail(data.email || '');
        setJobId(data.jobId || '');
        setJobTitle(data.jobTitle || '');
        setCompanyLogoURL(data.companyLogoURL || defaultCompanyLogoURL);
        setKnockoutQuestions(data.knockoutQuestions || []);
        setKnockoutAnswers(new Array(data.knockoutQuestions.length).fill(null));
        setApplicationId(data.applicationId);
      } catch (error) {
        console.error('Error fetching applicant data:', error);
      }
    };
    // Only fetch data if isPreview is explicitly true or false
    if (isPreview !== null) {
      fetchApplicantData();
    }
  }, [applicationShortCode, navigate, isPreview]); // Include navigate as a dependency in the useEffect

  const handleKnockoutAnswerChange = (index, answer) => {
    const updatedAnswers = [...knockoutAnswers];
    updatedAnswers[index] = answer;
    setKnockoutAnswers(updatedAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all questions are answered
    const unansweredQuestions = knockoutAnswers.some(
      (answer) => answer === null
    );

    if (unansweredQuestions) {
      setFormError('Please answer each question.');
      return; // Prevent form submission if any question is unanswered
    }

    setFormError(''); // Clear any previous error

    // You can use the existing startCallInline function here
    startCallInline(e);
  };

  // Hook into call events
  useEffect(() => {
    // If callId is set and WebSocket is not yet initialized
    if (callId && !ws) {
      const newWebSocket = new WebSocket(BACKEND_WEBSOCKET_URL); // Replace with your server WebSocket URL

      newWebSocket.onopen = () => {
        console.log('WebSocket connected');
        // Send the callId to the server
        newWebSocket.send(JSON.stringify({ callId }));
      };

      newWebSocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('Received WebSocket message:', message);
        // Handle incoming messages from server
        setCallStatus(message.status);
        setEndedReason(message.endedReason);
        console.log(endedReason);
      };

      newWebSocket.onclose = () => {
        console.log('WebSocket connection closed');
        setWs(null); // Reset WebSocket state on close
      };

      newWebSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
        newWebSocket.close(); // Close WebSocket on error
      };

      setWs(newWebSocket); // Store WebSocket connection
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId]);

  // Listen for changes in callStatus and update isButtonDisabled accordingly
  useEffect(() => {
    if (callStatus === 'in-progress') {
      setCallButtonText('Interview in progress...');
    } else if (
      callStatus === 'ended' &&
      endedReason === 'customer-did-not-answer'
    ) {
      setIsButtonDisabled(false);
    }
  }, [callStatus, endedReason]); // This hook runs every time callStatus changes

  // Listen for changes in isButtonDisabled and update isButtonDisabled accordingly
  useEffect(() => {
    if (!isButtonDisabled) {
      setCallButtonText('Interview Now');
    }
  }, [isButtonDisabled]); // This hook runs every time isButtonDisabled changes

  // Get day of the week for personalization
  const getDayOfWeek = () => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const today = new Date();
    return days[today.getDay()];
  };

  // Call start handler
  const startCallInline = async (e) => {
    e.preventDefault();

    // Ensure firstName and phoneNumber are provided
    if (!firstName || !phoneNumber || !lastName) return;

    // Set connection state
    setCallButtonText('Initiating call...');
    setIsButtonDisabled(true);

    const dayOfWeek = getDayOfWeek();

    const data = {
      firstName,
      lastName,
      phone: phoneNumber,
      email,
      jobId,
      dayOfWeek,
      knockouts: { jobId, knockoutAnswers },
      applicationId,
    };

    try {
      // Make the POST request to your Node.js server
      const response = await fetch(`${BACKEND_API_URL}/api/call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Handle the response
      const result = await response.json();

      if (response.ok) {
        if (result.hasInterviewed) {
          console.log('Applicant has already interviewed:', result);
          setCallButtonText('Interview Now');
          setFormError(result.message);
        } else if (result.hasPassedKnockouts) {
          console.log('Call initiated successfully:', result);
          setCallId(result.id); // Set the callId from the result
        } else if (!result.hasPassedKnockouts) {
          setCallButtonText('Interview Now');
          navigate(`/thank-you/jobs/${jobId}`, {
            state: { firstName, companyLogoURL: result.companyLogoURL },
          });
        }
      } else {
        console.error('Error initiating call:', result);
        setIsButtonDisabled(false);
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsButtonDisabled(false);
    } finally {
      // Reset the button state after completion
    }
  };

  // Display empty page until server returns response
  if (!jobTitle) {
    return (
      <div
        className="d-flex justify-content-center align-items-center text-center"
        style={{ height: '100vh' }}
      >
        <Helmet>
          <title>{`Applicant Job Screening`}</title>
          <meta name="description" content={`Interview Now`} />
          <meta property="og:title" content={`Applicant Job Screening`} />
          <meta property="og:description" content={`Interview Now`} />
          <meta
            property="og:image"
            content={
              companyLogoURL ||
              'https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG'
            }
          />
          <meta
            property="og:url"
            content={`https://www.instahear.ai/interview/${applicationShortCode}`}
          />
          <meta name="twitter:title" content={`Interview Now`} />
          <meta name="twitter:description" content={`Interview Now`} />
          <meta
            name="twitter:image"
            content={
              companyLogoURL ||
              'https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG'
            }
          />
        </Helmet>
        {''}
      </div>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>{`Applicant Screening | ${jobTitle}`}</title>
        <meta name="description" content={`Interview Now for ${jobTitle}`} />
        <meta
          property="og:title"
          content={`Applicant Screening | ${jobTitle}`}
        />
        <meta
          property="og:description"
          content={`Interview Now for ${jobTitle}`}
        />
        <meta
          property="og:image"
          content={
            companyLogoURL ||
            'https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG'
          }
        />
        <meta
          property="og:url"
          content={`https://www.instahear.ai/interview/${applicationShortCode}`}
        />
        <meta name="twitter:title" content={`Interview Now for ${jobTitle}`} />
        <meta
          name="twitter:description"
          content={`Interview Now for ${jobTitle}`}
        />
        <meta
          name="twitter:image"
          content={
            companyLogoURL ||
            'https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG'
          }
        />
      </Helmet>
      <JobCompanyLogo src={companyLogoURL} alt="Signal Logo" />
      <div className="form-container bg-white p-4 rounded shadow">
        <div className="text-center">
          <span className="fw-bold">{jobTitle}</span>
        </div>
        <Subtitle
          text={`Fill out your info below, and we'll call you for a quick interview.`}
        />
        <form onSubmit={handleSubmit} className="mt-4">
          {/* First Name and Last Name on the same line always */}
          <div className="row">
            <div className="col-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number:
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                if (value.length <= 10) setPhoneNumber(value);
              }}
              maxLength="10"
              minLength="10"
              required
            />
          </div>

          {!initialEmail && (
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          )}

          {knockoutQuestions.map((questionObj, index) => (
            <div key={index} className="mt-4 mb-4">
              {' '}
              {/* Increased margin below each question */}
              <p className="form-label">{questionObj.question}</p>{' '}
              {/* Render the question text */}
              <div>
                <label style={{ marginRight: '10px' }}>
                  <input
                    type="radio"
                    value="Yes"
                    checked={knockoutAnswers[index] === 'Yes'}
                    onChange={() => handleKnockoutAnswerChange(index, 'Yes')}
                    required
                  />
                  <span style={{ marginLeft: '5px' }}>Yes</span>
                </label>
                <label style={{ marginRight: '10px' }}>
                  <input
                    type="radio"
                    value="No"
                    checked={knockoutAnswers[index] === 'No'}
                    onChange={() => handleKnockoutAnswerChange(index, 'No')}
                    required
                  />
                  <span style={{ marginLeft: '5px' }}>No</span>
                </label>
              </div>
            </div>
          ))}

          {/* Display error if any knockout question is unanswered */}
          {formError && (
            <div className="alert alert-danger mt-3">{formError}</div>
          )}

          <button
            type="submit"
            className="btn btn-primary btn-custom w-100 fw-bold"
            disabled={isButtonDisabled}
          >
            {callButtonText}{' '}
            <i className="fas fa-phone" style={{ marginLeft: '10px' }}></i>
          </button>

          <TermsDisclaimer />
        </form>
      </div>

      {/* Footer */}
      <footer className="text-center mt-3">
        2024 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default InterviewNowPage;
