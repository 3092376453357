// InfoTooltip.jsx
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './FormRequirements.css';

const InfoTooltip = ({ message }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{message}</Tooltip>}>
      <span className="info-icon">i</span>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
