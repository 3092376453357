import React from 'react';
import { Link } from 'react-router-dom';

const TermsCheckbox = ({
  mode = 'public-demo',
  isChecked,
  onChange,
  showError,
}) => {
  return (
    <div
      className="form-group d-flex mb-3"
      style={{ alignItems: 'flex-start' }}
    >
      <input
        type="checkbox"
        id="terms"
        checked={isChecked}
        onChange={onChange}
        required // Mark the checkbox as required
        style={{ marginTop: '0.3rem' }} // Adjust margin for better alignment
      />
      {mode && mode === 'admin-demo' && (
        <label htmlFor="terms" className="ms-2 mb-0 disclaimer">
          Applicant agrees to receive automated job interview messages at the
          phone number provided. Consent is not a condition for job offer. Msg &
          data rates may apply. Msg frequency varies. Applicant can reply HELP
          for help and STOP to cancel. View our{' '}
          <Link to="/terms">Service Terms</Link> and{' '}
          <Link to="/privacy">Privacy Policy</Link> for more information.
        </label>
      )}
      {mode && mode === 'public-demo' && (
        <label htmlFor="terms" className="ms-2 mb-0 disclaimer">
          I agree to receive automated job interview messages at the phone
          number provided. Consent is not a condition for job offer. Msg & data
          rates may apply. Msg frequency varies. Reply HELP for help and STOP to
          cancel. Applicant can visit our <Link to="/terms">Service Terms</Link>{' '}
          and <Link to="/privacy">Privacy Policy</Link>.
        </label>
      )}
      {showError && (
        <span className="text-danger ms-2">
          You must agree to the terms to proceed.
        </span>
      )}
    </div>
  );
};

export default TermsCheckbox;
