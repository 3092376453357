import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { Pie, Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './PilotAnalyticsPage.css';
import 'react-datepicker/dist/react-datepicker.css';
import AppPageTitle from '../components/app/AppPageTitle';
import { Fire } from 'react-bootstrap-icons';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const PilotAnalyticsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams(); // Correctly call useParams at the top level
  const [sessionAnalytics, setSessionAnalytics] = useState(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Memoize urlParams to avoid re-creation on every render
  const urlQueryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const initialStartDate = urlQueryParams.get('startDate')
    ? new Date(urlQueryParams.get('startDate'))
    : new Date(new Date().setDate(new Date().getDate() - 8));
  const initialEndDate = urlQueryParams.get('endDate')
    ? new Date(urlQueryParams.get('endDate'))
    : new Date(new Date().setDate(new Date().getDate() - 1));
  const initialJobId = params['jobId'] || null;

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [jobId, setJobId] = useState(initialJobId);

  const updateUrlWithDateRange = useCallback(
    (start, end, jobId) => {
      const newUrl = `${
        location.pathname
      }?startDate=${start.toISOString()}&endDate=${end.toISOString()}${
        jobId ? `&jobId=${jobId}` : ''
      }`;
      if (newUrl !== `${location.pathname}${location.search}`) {
        navigate(newUrl, { replace: true });
      }
    },
    [location.pathname, location.search, navigate]
  );

  useEffect(() => {
    // Ensure URL always has the startDate, endDate, and jobId if applicable
    if (!urlQueryParams.get('startDate') || !urlQueryParams.get('endDate')) {
      updateUrlWithDateRange(startDate, endDate, jobId);
    }
  }, [startDate, endDate, updateUrlWithDateRange, urlQueryParams, jobId]);

  useEffect(() => {
    // Synchronize state with URL parameters
    const newStartDate = urlQueryParams.get('startDate')
      ? new Date(urlQueryParams.get('startDate'))
      : startDate;
    const newEndDate = urlQueryParams.get('endDate')
      ? new Date(urlQueryParams.get('endDate'))
      : endDate;

    if (
      newStartDate.toISOString() !== startDate.toISOString() ||
      newEndDate.toISOString() !== endDate.toISOString()
    ) {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }

    const jobIdFromUrl = params.jobId;

    // Set jobId to the value from the URL or null if it doesn't exist
    if (jobIdFromUrl !== jobId) {
      setJobId(jobIdFromUrl || null);
    }
  }, [startDate, endDate, urlQueryParams, params.jobId, jobId]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const filters = {
          startDate,
          endDate,
          browserTimezone: timezone,
        };

        if (jobId) {
          filters.jobId = jobId;
        }

        const response = await fetch(
          `${BACKEND_API_URL}/api/sessions/analytics`,
          {
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(filters),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch sessions data');

        const data = await response.json();
        setSessionAnalytics(data);
      } catch (error) {
        console.error('Error fetching sessions data:', error);
      }
    };
    fetchSessions();
  }, [startDate, endDate, timezone, jobId]);

  const calculateInterviewResults = () => {
    const totalSessions = sessionAnalytics.totalSessionsCount;
    const resultLabels = Object.keys(sessionAnalytics.sessionResults);
    const colors = {
      PASSED: '#198754', // green
      FAILED: '#dc3545', // red
      INCOMPLETE: '#6c757d', // gray
      IN_PROGRESS: '#0d6efd', // blue
      KNOCKOUT_FAILED: '#ff9332', // yellow
    };
    return {
      labels: resultLabels.map(
        (label) => `${label} (${sessionAnalytics.sessionResults[label] || 0})`
      ),
      datasets: [
        {
          data: resultLabels.map(
            (label) => sessionAnalytics.sessionResults[label] || 0
          ),
          backgroundColor: resultLabels.map((label) => colors[label]),
          datalabels: {
            formatter: (value) =>
              `${((value / totalSessions) * 100).toFixed(1)}%`,
          },
        },
      ],
    };
  };

  const calculateInterviewSpeed = () => {
    const totalSpeed = sessionAnalytics.totalSessionsCount;
    const speedPercentages = Object.keys(
      sessionAnalytics.minutesToInterview
    ).map(
      (key) => (sessionAnalytics.minutesToInterview[key] / totalSpeed) * 100
    );

    return {
      labels: Object.keys(sessionAnalytics.minutesToInterview),
      datasets: [
        {
          label: 'Minutes To Interview',
          data: speedPercentages,
          backgroundColor: '#625df5', // Orange for all bars
          datalabels: {
            formatter: (value) => `${value.toFixed(1)}%`,
          },
        },
      ],
    };
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    updateUrlWithDateRange(start, end, jobId);
  };

  return (
    <div className="wide-container small-font">
      {sessionAnalytics && sessionAnalytics.jobTitle ? (
        <AppPageTitle
          preTitle={'Job Analytics: '}
          title={sessionAnalytics.jobTitle}
          subtitle={sessionAnalytics.jobSourceId || ''}
        ></AppPageTitle>
      ) : (
        <AppPageTitle
          preTitle={'Analytics: '}
          title={'All Jobs'}
        ></AppPageTitle>
      )}
      {/* Date Range Filter */}
      <Row className="mb-4">
        <Col>
          <Form.Label>Date Range ({timezone}):</Form.Label>
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateChange(date, endDate)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            <span className="mx-2">to</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateChange(startDate, date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      {sessionAnalytics && (
        <Row className="mt-4">
          {/* Interview Results - Pie Chart */}
          <Col md={6}>
            <Card
              className="analytics-card shadow-sm"
              style={{ maxHeight: '500px' }}
            >
              <Card.Body>
                <Card.Title>Interview Results</Card.Title>
                <Row>
                  <Col md={4}>
                    <ul className="list-unstyled">
                      {calculateInterviewResults().labels.map(
                        (label, index) => (
                          <li
                            key={index}
                            style={{
                              color:
                                calculateInterviewResults().datasets[0]
                                  .backgroundColor[index],
                            }}
                          >
                            {label}
                          </li>
                        )
                      )}
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div style={{ height: '350px' }}>
                      <Pie
                        data={calculateInterviewResults()}
                        plugins={[ChartDataLabels]}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            datalabels: {
                              color: '#fff',
                              font: { weight: 'bold' },
                              formatter: (value, context) => {
                                const total = context.dataset.data.reduce(
                                  (a, b) => a + b,
                                  0
                                );
                                const percentage = (
                                  (value / total) *
                                  100
                                ).toFixed(1);
                                return `${percentage}% (${value})`;
                              },
                            },
                            legend: { display: false },
                            tooltip: {
                              callbacks: {
                                label: (tooltipItem) => {
                                  const value = tooltipItem.raw;
                                  const total = tooltipItem.dataset.data.reduce(
                                    (a, b) => a + b,
                                    0
                                  );
                                  const percentage = (
                                    (value / total) *
                                    100
                                  ).toFixed(1);
                                  return `${percentage}% (${value})`;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* Interview Speed - Bar Chart */}
          <Col md={6}>
            <Card
              className="analytics-card shadow-sm"
              style={{ maxHeight: '500px' }}
            >
              <Card.Body>
                <Card.Title>Time To Interview</Card.Title>
                <div style={{ height: '350px' }}>
                  <Bar
                    data={calculateInterviewSpeed()}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem) => {
                              return [
                                `${tooltipItem.raw.toFixed(
                                  1
                                )}% of interviews started`,
                                `${tooltipItem.label} after applicant was engaged.`,
                              ];
                            },
                          },
                        },
                        legend: { display: false },
                      },
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: 'Minutes between Engaged and Interview Start',
                            color: '#666',
                            font: { size: 12, weight: 'normal' },
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: '% of Interviews',
                            color: '#666',
                            font: { size: 12, weight: 'normal' },
                          },
                          ticks: {
                            callback: (value) => `${value}%`,
                          },
                          beginAtZero: true,
                          max: 100,
                        },
                      },
                    }}
                  />
                </div>
                <p className="text-center">
                  <span className="fw-bold" style={{ fontSize: '1.1rem' }}>
                    <Fire color="orange" />{' '}
                    {sessionAnalytics.percentSessionsSameDay}%
                  </span>{' '}
                  of interviews began on the{' '}
                  <span className="fw-bold" style={{ fontSize: '1.05rem' }}>
                    Same Day
                  </span>{' '}
                  the applicant applied.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PilotAnalyticsPage;
