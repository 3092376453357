import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  let BACKEND_API_URL = 'https://api.instahear.ai';

  if (process.env.REACT_APP_ENV === 'development') {
    BACKEND_API_URL = 'http://localhost:3001';
  }

  React.useEffect(() => {
    const performLogout = async () => {
      try {
        // Call server-side endpoint to clear the authToken cookie
        await fetch(`${BACKEND_API_URL}/api/logout`, {
          method: 'POST',
          credentials: 'include',
        });

        // Clear localStorage items
        localStorage.removeItem('userEmail');
        localStorage.removeItem('companyName');
        localStorage.removeItem('homeLink');
        localStorage.removeItem('isAdmin');

        // Navigate to login page
        navigate('/login');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    performLogout();
  }, [navigate, BACKEND_API_URL]);

  return null;
};

export default LogoutPage;
