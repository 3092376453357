import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let BACKEND_API_URL = 'https://api.instahear.ai';

  if (process.env.REACT_APP_ENV === 'development') {
    BACKEND_API_URL = 'http://localhost:3001';
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/auth/check`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Authentication check failed');
        }

        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated || false);
      } catch (error) {
        console.error('Error during authentication check:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [BACKEND_API_URL]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a spinner/loading component
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
