import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce'; // Install lodash.debounce using `npm install lodash.debounce`
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  Card,
  Container,
  Form,
  Button,
  Tabs,
  Tab,
  ListGroup,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppPageTitle from '../components/app/AppPageTitle';
import RequiredAsterisk from './RequiredAsterisk';
import InfoTooltip from './InfoTooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FormRequirements.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const DEBOUNCE_DELAY = 500;

const CompanyAssessmentDetailsPage = ({ mode }) => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [assessment, setAssessment] = useState(null);
  const [dealbreakerTopicNames, setDealbreakerTopicNames] = useState([]);
  const [activeTab, setActiveTab] = useState('details');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const fetchDraftInitialized = useRef(false); // To track if `fetchOrCreateDraft` has been called
  const debounceSaveRef = useRef(null); // Ref for the debounced save function

  const fetchDealbreakerTopicNames = async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/dealbreaker-topics`,
        {
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch Call Script dealbreaker topics');
      }

      const data = await response.json();
      // Prepare options for react-select
      setDealbreakerTopicNames(
        data.dealbreakerTopicNames.map(
          (db) =>
            ({
              value: db.name,
              label: db.name,
            } || [])
        )
      );
    } catch (error) {
      console.error(
        'Error fetching Call Script company dealbreaker topics:',
        error
      );
    }
  };

  useEffect(() => {
    // Initialize the debounced save function
    debounceSaveRef.current = debounce(async (fieldData) => {
      if (assessment?.id) {
        try {
          const response = await fetch(
            `${BACKEND_API_URL}/api/assessments/draft/${assessment.id}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(fieldData),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Error autosaving draft:', errorData);
            return;
          }

          const result = await response.json();
          setAssessment((prevAssessment) => ({
            ...prevAssessment,
            unSavedChanges: result.assessment.unSavedChanges,
            updatedAt: result.assessment.updatedAt,
          }));
          console.log('Draft saved successfully');
        } catch (error) {
          console.error('Error in autosaveDraft:', error);
        }
      }
    }, DEBOUNCE_DELAY);

    return () => {
      // Cleanup debounce on component unmount
      debounceSaveRef.current?.cancel();
    };
  }, [assessment?.id]);

  const handleFieldChange = (field, value) => {
    // Update state immediately for responsive UI
    setAssessment((prevAssessment) => ({
      ...prevAssessment,
      [field]: value,
    }));

    // Debounce save
    debounceSaveRef.current({ [field]: value });
  };

  useEffect(() => {
    if (!fetchDraftInitialized.current && assessmentId) {
      fetchDraftInitialized.current = true;
      fetchOrCreateDraft(assessmentId);
      fetchDealbreakerTopicNames();
    }
  }, [assessmentId]);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const publishAssessment = async () => {
    setPublishing(true);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/publish/${assessment.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(assessment), // Include assessment data in the request body
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      const data = await response.json();

      toast.success('Call Script published successfully!', {
        position: 'top-right',
        autoClose: 5000,
      });

      // Optional: Perform any additional actions after successful publish
      console.log(`Published job data: ${data.id}`);
      setAssessment((prevAssessment) => ({
        ...prevAssessment,
        parentAssessment: {
          publishedAt: new Date(),
        },
        unSavedChanges: data.assessment.unSavedChanges,
        updatedAt: data.assessment.updatedAt,
      }));
    } catch (error) {
      console.error('Error publishing Call Script:', error);
      toast.error(
        'An unexpected error occurred while publishing the Call Script.',
        {
          position: 'top-right',
          autoClose: 5000,
        }
      );
    } finally {
      setPublishing(false);
    }
  };

  const fetchOrCreateDraft = async (assessmentId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/draft/${assessmentId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch or create draft Call Script');
      }

      const data = await response.json();
      setAssessment(data.assessment);
    } catch (error) {
      console.error('Error fetching or creating draft:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    navigate(`${location.pathname}?tab=${tabKey}`, { replace: true });
  };

  const handleDelete = async () => {
    try {
      // Confirm the deletion with the user
      const confirmation = window.confirm(
        'Are you sure you want to delete this Call Script? This action cannot be undone.'
      );
      if (!confirmation) return;

      // Call the DELETE API
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/${assessmentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Assessment deleted successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/call-scripts');
    } catch (error) {
      console.error('Error deleting Call Script:', error);
      toast.error(
        'An unexpected error occurred while deleting the Call Script.',
        {
          position: 'top-right',
          autoClose: 5000,
        }
      );
    }
  };

  const handleArchive = async () => {
    try {
      // Confirm the archive action with the user
      const confirmation = window.confirm(
        'Are you sure you want to archive this Call Script? This action can be undone by restoring the Call Script.'
      );
      if (!confirmation) return;

      // Call the POST API to archive the job
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/archive/${assessmentId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Call Script archived successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/call-scripts');
    } catch (error) {
      console.error('Error archiving Call Script:', error);
      toast.error(
        'An unexpected error occurred while archiving the Call Script.',
        {
          position: 'top-right',
          autoClose: 5000,
        }
      );
    }
  };

  const handleClone = async () => {
    try {
      // Confirm the archive action with the user
      const confirmation = window.confirm(
        'Are you sure you want to clone this Call Script?'
      );
      if (!confirmation) return;

      // Call the POST API to archive the Call Script
      const response = await fetch(
        `${BACKEND_API_URL}/api/assessments/clone/${assessmentId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      // Show success toast and navigate to /jobs
      toast.success('Call Script cloned successfully.', {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/call-scripts');
    } catch (error) {
      console.error('Error cloning Call Script:', error);
      toast.error(
        'An unexpected error occurred while cloning the Call Script.',
        {
          position: 'top-right',
          autoClose: 5000,
        }
      );
    }
  };

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const publishedDate = new Date(timestamp);
    const diffInMs = now - publishedDate;
    const diffInMinutes = Math.floor(diffInMs / 60000);

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes === 1) return '1 minute ago';
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours === 1) return '1 hour ago';
    if (diffInHours < 24) return `${diffInHours} hours ago`;

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays === 1) return '1 day ago';
    return `${diffInDays} days ago`;
  };

  const moveItem = (arr, index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= arr.length) return arr;

    const updatedArray = [...arr];
    const [movedItem] = updatedArray.splice(index, 1);
    updatedArray.splice(newIndex, 0, movedItem);
    return updatedArray;
  };

  const deleteItem = (arr, index) => {
    const updatedArray = arr.filter((_, i) => i !== index);
    return updatedArray;
  };

  const addNewKnockoutQuestion = () => {
    const updatedQuestions = [
      ...assessment.knockoutQuestions,
      { question: '', correctAnswer: '' }, // Add a new blank question
    ];
    handleFieldChange('knockoutQuestions', updatedQuestions);
    // Scroll to the bottom of the page
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const addNewInterviewQuestion = () => {
    const updatedQuestions = [
      ...assessment.questions,
      { question: '', clarifications: '' }, // Add a new blank question
    ];
    handleFieldChange('questions', updatedQuestions);
    // Scroll to the bottom of the page
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  // Render loading spinner or job details form
  if (loading) return <div>Loading...</div>;
  if (!assessment) return null;

  return (
    <Container
      className="mt-4"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <ToastContainer />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <AppPageTitle title={assessment.name} />

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {assessment.parentAssessment?.publishedAt && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '30px',
                }}
              >
                <Form.Check
                  type="switch"
                  id="job-status-toggle"
                  checked={assessment.isActive}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleFieldChange('isActive', isChecked);
                  }}
                  label=""
                  className="me-2"
                />
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {assessment.isActive
                    ? 'Available for Jobs'
                    : 'Not Available for Jobs'}
                  <InfoTooltip
                    message={
                      'Enable Call Script to be available for Job interviews.'
                    }
                  />
                </span>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="primary"
                size="sm"
                className="fw-bold"
                disabled={publishing || !assessment.unSavedChanges?.hasChanges}
                onClick={publishAssessment}
                style={{ marginRight: '10px' }}
              >
                Publish Call Script
              </Button>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {/* Dropdown menu button */}
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="actionDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    &#8942; {/* Vertical ellipsis icon */}
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="actionDropdown"
                  >
                    {assessment.canArchive && (
                      <>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleArchive}
                          >
                            Archive
                          </button>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </>
                    )}
                    <li>
                      <button className="dropdown-item" onClick={handleClone}>
                        Clone
                      </button>
                    </li>
                    {assessment.canDelete && (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginTop: '20px',
              marginRight: '10px',
              fontSize: '.8rem',
              color: 'gray',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!assessment.parentAssessment?.isDraft &&
                assessment.unSavedChanges?.hasChanges &&
                assessment.unSavedChanges.fields && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {`This Call Script has unpublished changes in the following fields:`}
                        <ul style={{ margin: 0, padding: '5px 0 0 15px' }}>
                          {assessment.unSavedChanges.fields.map(
                            (field, index) => (
                              <li key={index} style={{ fontSize: '0.85rem' }}>
                                {field}
                              </li>
                            )
                          )}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <span
                      className="text-warning"
                      style={{
                        display: 'inline-block',
                        width: '9px',
                        height: '9px',
                        backgroundColor: 'currentColor',
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    ></span>
                  </OverlayTrigger>
                )}
              <div style={{ fontWeight: 'bold' }}>Last Published:</div>
            </div>
            <div>
              {assessment.parentAssessment?.publishedAt
                ? formatTimeAgo(assessment.parentAssessment?.publishedAt)
                : 'Never'}
            </div>
            <div style={{ marginTop: '8px', fontWeight: 'bold' }}>
              Last Updated:
            </div>
            <div>
              {assessment.updatedAt
                ? formatTimeAgo(assessment.updatedAt)
                : 'Never'}
            </div>
          </div>
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        className="mb-3 small-font"
      >
        {/* Details Tab */}
        <Tab
          eventKey="details"
          title={
            <span
              style={{
                fontWeight: activeTab === 'details' ? 'bold' : 'normal',
              }}
            >
              Details
            </span>
          }
        >
          <Card className="mb-4">
            <Card.Body className="mb-4">
              <Form.Group className="small-font">
                <Form.Label className="small-font">
                  Call Script Name
                  <RequiredAsterisk />
                  <InfoTooltip message="Internal name, used to select Assessment when creating or editing a Job." />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={assessment.name || ''}
                  style={{ width: '400px' }}
                  onChange={(e) => handleFieldChange('name', e.target.value)}
                  className="small-font"
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Tab>
        {/* Knockout Questions Tab */}
        <Tab
          eventKey="knockoutQuestions"
          title={
            <span
              style={{
                fontWeight:
                  activeTab === 'knockoutQuestions' ? 'bold' : 'normal',
              }}
            >
              Knockout Questions
            </span>
          }
        >
          <Card className="mb-4">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                <div className="d-flex align-items-center">
                  <span className="small-font fw-bold me-2">
                    Knockout Questions
                  </span>
                  <InfoTooltip message="Optional. These questions can disqualify the Applicant before they reach the AI phone interview." />
                </div>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => addNewKnockoutQuestion()}
                >
                  Add New
                </Button>
              </div>
              <ListGroup variant="flush">
                {assessment.knockoutQuestions.map((item, index) => (
                  <ListGroup.Item key={index} className="mb-3">
                    <Card>
                      <Card.Body>
                        <Form.Group as={Row} className="mb-2 small-font">
                          <Form.Label column sm={3} className="small-font">
                            Question
                            <RequiredAsterisk />
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              type="text"
                              value={item.question}
                              onChange={(e) => {
                                const updatedQuestions = [
                                  ...assessment.knockoutQuestions,
                                ];
                                updatedQuestions[index].question =
                                  e.target.value;
                                handleFieldChange(
                                  'knockoutQuestions',
                                  updatedQuestions
                                );
                              }}
                              className="small-font"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2 small-font">
                          <Form.Label column sm={3} className="small-font">
                            Correct Answer
                            <RequiredAsterisk />
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              as="select"
                              value={item.correctAnswer}
                              onChange={(e) => {
                                const updatedQuestions = [
                                  ...assessment.knockoutQuestions,
                                ];
                                updatedQuestions[index].correctAnswer =
                                  e.target.value;
                                handleFieldChange(
                                  'knockoutQuestions',
                                  updatedQuestions
                                );
                              }}
                              className="small-font"
                            >
                              <option value="">Select Answer</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Control>
                          </Col>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="link"
                            size="sm"
                            disabled={index === 0}
                            onClick={() =>
                              handleFieldChange(
                                'knockoutQuestions',
                                moveItem(
                                  assessment.knockoutQuestions,
                                  index,
                                  -1
                                )
                              )
                            }
                          >
                            ↑
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            disabled={
                              index === assessment.knockoutQuestions.length - 1
                            }
                            onClick={() =>
                              handleFieldChange(
                                'knockoutQuestions',
                                moveItem(assessment.knockoutQuestions, index, 1)
                              )
                            }
                          >
                            ↓
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleFieldChange(
                                'knockoutQuestions',
                                deleteItem(assessment.knockoutQuestions, index)
                              )
                            }
                          >
                            ✖
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {assessment.knockoutQuestions.length > 3 && (
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewKnockoutQuestion()}
                  >
                    Add New
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Tab>
        {/* Interview Questions Tab */}
        <Tab
          eventKey="interviewQuestions"
          title={
            <span
              style={{
                fontWeight:
                  activeTab === 'interviewQuestions' ? 'bold' : 'normal',
              }}
            >
              Interview Questions
            </span>
          }
        >
          <Card className="mb-4">
            <Card.Body>
              {/* Top Add New Button */}
              <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                <div className="d-flex align-items-center">
                  <span className="small-font fw-bold">
                    Interview Questions
                  </span>
                  <RequiredAsterisk />
                  <InfoTooltip message="Required for the AI interviewer to prompt applicant questions during the phone interview." />
                </div>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    addNewInterviewQuestion();
                  }}
                >
                  Add New
                </Button>
              </div>
              <ListGroup variant="flush">
                {assessment.questions.map((item, index) => (
                  <ListGroup.Item key={index} className="mb-3">
                    <Card>
                      <Card.Body>
                        {/* Question Field */}
                        <Form.Group as={Row} className="mb-2 small-font">
                          <Form.Label column sm={3} className="small-font">
                            Question {index + 1}
                            <RequiredAsterisk />
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              as="textarea"
                              value={item.question}
                              rows={2}
                              onChange={(e) => {
                                const updatedQuestions = [
                                  ...assessment.questions,
                                ];
                                updatedQuestions[index].question =
                                  e.target.value;
                                handleFieldChange(
                                  'questions',
                                  updatedQuestions
                                );
                              }}
                              className="small-font"
                            />
                          </Col>
                        </Form.Group>

                        {/* Clarifying Questions */}
                        <Form.Group as={Row} className="mb-2 small-font">
                          <Form.Label column sm={3} className="small-font">
                            Clarifying Questions
                            <InfoTooltip message="Optional setting for the AI interviewer to ask follow-up questions based on the applicant’s responses." />
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              value={item.clarifications}
                              onChange={(e) => {
                                const updatedQuestions = [
                                  ...assessment.questions,
                                ];
                                updatedQuestions[index].clarifications =
                                  e.target.value;
                                handleFieldChange(
                                  'questions',
                                  updatedQuestions
                                );
                              }}
                              className="small-font"
                            />
                          </Col>
                        </Form.Group>

                        {/* Dealbreaker Toggle */}
                        <Form.Group
                          as={Row}
                          className="mb-2 small-font align-items-center"
                        >
                          <Form.Label column sm={3} className="small-font">
                            Is Dealbreaker?
                            <InfoTooltip message="Set to ‘Yes’ if the applicant’s response will determine whether they pass or fail." />
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Check
                              type="switch"
                              id={`dealbreaker-toggle-${index}`}
                              label={item.isDealbreaker ? 'Yes' : 'No'}
                              checked={item.isDealbreaker || false}
                              onChange={(e) => {
                                const updatedQuestions = [
                                  ...assessment.questions,
                                ];
                                updatedQuestions[index].isDealbreaker =
                                  e.target.checked;
                                handleFieldChange(
                                  'questions',
                                  updatedQuestions
                                );
                              }}
                            />
                          </Col>
                        </Form.Group>

                        {/* Success Criteria (conditionally displayed) */}
                        {item.isDealbreaker && (
                          <>
                            <Form.Group as={Row} className="mb-2 small-font">
                              <Form.Label column sm={3} className="small-font">
                                Dealbreaker Topic
                                <RequiredAsterisk />
                                <InfoTooltip message="Required for AI to summarize interview and determine if applicant Passes or Fails." />
                              </Form.Label>
                              <Col sm={9}>
                                <Select
                                  options={dealbreakerTopicNames.map(
                                    (topic) => ({
                                      value: topic.value,
                                      label: topic.label,
                                    })
                                  )}
                                  value={
                                    item.dealbreakerTopicName
                                      ? {
                                          value: item.dealbreakerTopicName,
                                          label: item.dealbreakerTopicName,
                                        }
                                      : null
                                  }
                                  onChange={(selectedOption) => {
                                    const updatedQuestions = [
                                      ...assessment.questions,
                                    ];
                                    updatedQuestions[
                                      index
                                    ].dealbreakerTopicName = selectedOption
                                      ? selectedOption.value
                                      : null;
                                    handleFieldChange(
                                      'questions',
                                      updatedQuestions
                                    );
                                  }}
                                  placeholder="Select a Dealbreaker Topic"
                                  isClearable
                                  classNamePrefix="react-select"
                                  className="small-font"
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2 small-font">
                              <Form.Label column sm={3} className="small-font">
                                Dealbreaker Criteria
                                <RequiredAsterisk />
                                <InfoTooltip message="The criteria that the applicant must meet to pass this question." />
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={item.dealbreakerCriteria || ''}
                                  onChange={(e) => {
                                    const updatedQuestions = [
                                      ...assessment.questions,
                                    ];
                                    updatedQuestions[
                                      index
                                    ].dealbreakerCriteria = e.target.value;
                                    handleFieldChange(
                                      'questions',
                                      updatedQuestions
                                    );
                                  }}
                                  className="small-font"
                                />
                              </Col>
                            </Form.Group>
                          </>
                        )}

                        {/* Move Up/Down/Delete Buttons */}
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="link"
                            size="sm"
                            disabled={index === 0}
                            onClick={() =>
                              handleFieldChange(
                                'questions',
                                moveItem(assessment.questions, index, -1)
                              )
                            }
                          >
                            ↑
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            disabled={index === assessment.questions.length - 1}
                            onClick={() =>
                              handleFieldChange(
                                'questions',
                                moveItem(assessment.questions, index, 1)
                              )
                            }
                          >
                            ↓
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleFieldChange(
                                'questions',
                                deleteItem(assessment.questions, index)
                              )
                            }
                          >
                            ✖
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {/* Bottom Add New Button */}
              {assessment.questions.length > 2 && (
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => {
                      addNewInterviewQuestion();
                    }}
                  >
                    Add New
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default CompanyAssessmentDetailsPage;
