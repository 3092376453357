import React from 'react';

const JobCompanyLogo = ({ src, alt, width = '25%' }) => {
  return (
    <div className="logo-container text-center">
      <img src={src} alt={alt} style={{ width }} />
    </div>
  );
};

export default JobCompanyLogo;
