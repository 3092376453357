import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TermsCheckbox from './components/jobs/TermsCheckbox';
import JobCompanyLogo from './components/jobs/JobCompanyLogo';
import InstaHearLogoSrc from './instahear-logo.png';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const DemoPage = () => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobOptions, setJobOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Submit Application');
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [showCheckboxError, setShowCheckboxError] = useState(false); // Validation error for checkbox

  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  // Parse URL parameters and populate the form if values are present
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const firstNameFromUrl = params.get('firstName');
    const lastNameFromUrl = params.get('lastName');
    const phoneFromUrl = params.get('phone');
    const emailFromUrl = params.get('email');
    const jobIdFromUrl = params.get('jobId');
    const acceptTermsFromUrl = params.get('acceptTerms') === 'true';

    if (firstNameFromUrl) setFirstName(firstNameFromUrl);
    if (lastNameFromUrl) setLastName(lastNameFromUrl);
    if (phoneFromUrl) setPhone(phoneFromUrl);
    if (emailFromUrl) setEmail(emailFromUrl);
    if (jobIdFromUrl) setJobId(jobIdFromUrl);
    if (acceptTermsFromUrl) setIsChecked(true);
  }, []);

  // Fetch job options on page load
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/demo-jobs`);
        const data = await response.json();
        setJobOptions(data.demoJobs || []);
      } catch (error) {
        console.error('Error fetching job options:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the checkbox is selected
    if (!isChecked) {
      setShowCheckboxError(true); // Show error if checkbox is not selected
      return;
    }

    setIsSubmitting(true);

    const formData = {
      channel: 'Demo',
      lastName,
      firstName,
      phone,
      email,
      jobId,
    };

    try {
      const response = await fetch(`${BACKEND_API_URL}/api/application/demo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setHasSubmitted(true);
        setButtonText('Application Sent!');
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container my-5">
      <JobCompanyLogo src={InstaHearLogoSrc} alt="Company Logo" />

      <div className="form-container bg-white px-4 py-5 rounded shadow">
        <p className="pb-3 text-center subtitle-demo-form">
          Fill out your info below, and we'll text you a link to begin your
          phone interview.
        </p>

        <form onSubmit={handleSubmit}>
          {/* First Name and Last Name on the same line for both mobile and desktop */}
          <div className="row">
            <div className="form-group col-6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="form-group col-6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>

          {/* Phone and Email on the same line on desktop, but stacked on mobile */}
          <div className="row">
            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                className="form-control"
                value={phone}
                onChange={(e) => {
                  // Allow only digits
                  const onlyDigits = e.target.value.replace(/\D/g, '');
                  setPhone(onlyDigits);
                }}
                maxLength="10" // Limits input to 10 characters
                minLength="10"
                required
              />
            </div>

            <div className="form-group col-12 col-md-6 mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="jobId" className="form-label">
              Job Position:
            </label>
            <select
              id="jobId"
              className="form-select"
              value={jobId}
              onChange={(e) => setJobId(e.target.value)}
              required
            >
              <option value="">Select a Job</option>
              {jobOptions.map((job) => (
                <option key={job.jobId} value={job.jobId}>
                  {job.jobTitle}
                </option>
              ))}
            </select>
          </div>

          {/* Pass the validation error to the TermsCheckbox */}
          <TermsCheckbox
            isChecked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
              setShowCheckboxError(false); // Hide error when checked
            }}
            showError={showCheckboxError} // Show error message when form is submitted without checkbox
          />

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-3"
            disabled={isSubmitting || hasSubmitted}
          >
            {buttonText}
          </button>
        </form>
      </div>

      <footer className="text-center mt-5">
        2024 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default DemoPage;
