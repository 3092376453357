import React from 'react';
import { Link } from 'react-router-dom';

// Subtitle Component
const TermsDisclaimer = () => {
  return (
    <p className="disclaimer mt-4">
      By submitting this form, I agree to receive automated job interview
      messages at the phone number provided. Consent is not a condition for job
      offer. Msg & data rates may apply. Msg frequency varies. Reply HELP for
      help and STOP to cancel. View our <Link to="/terms">Service Terms</Link>{' '}
      and <Link to="/privacy">Privacy Policy</Link>.
    </p>
  );
};

export default TermsDisclaimer;
