import React from 'react';

// Subtitle Component
const Subtitle = ({ text }) => {
  return (
    <p className="subtitle text-center">
      {text}
    </p>
  );
};

export default Subtitle;
