import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Spinner } from 'react-bootstrap';

const RedirectWithSpinner = () => {
  useEffect(() => {
    // Redirect after a delay
    const timer = setTimeout(() => {
      window.location.href = 'https://app.apollo.io/#/meet/perry_petrozelli';
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        {/* Meta Tags for SEO */}
        <title>Book a Discovery Call with InstaHear</title>
        <meta
          name="description"
          content="Book a Discovery Call with Perry Petrozelli at InstaHear.ai."
        />
        <meta
          property="og:title"
          content="Book a Discovery Call with Perry Petrozelli"
        />
        <meta
          property="og:description"
          content="Book a Discovery Call with Perry Petrozelli at InstaHear.ai."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG"
        />
        <meta
          property="og:url"
          content="https://www.instahear.ai/book-a-meeting"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://storage.googleapis.com/instahear-react-images/IG-logo-square-transparent.PNG"
        />
        <meta
          name="twitter:title"
          content="Book a meeting with Perry Petrozelli"
        />
        <meta
          name="twitter:description"
          content="Quickly join the meeting with Perry Petrozelli at InstaHear.ai. Redirecting you now..."
        />

        {/* Favicon */}
        <link
          rel="icon"
          href="https://storage.googleapis.com/instahear-react-images/meta-images-seo/favicon-32x32.png"
        />
        <link
          rel="apple-touch-icon"
          href="https://storage.googleapis.com/instahear-react-images/meta-images-seo/apple-touch-icon.png"
        />
      </Helmet>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start', // Align items at the top
          height: '100vh',
          backgroundColor: '#f9f9f9',
          paddingTop: '30vh', // Add padding to push content 1/3 down the page
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Spinner
            animation="border"
            role="status"
            style={{ marginBottom: '10px' }}
          >
            <span className="visually-hidden">Loading Meeting Calendar...</span>
          </Spinner>
          <h1>Book a Discovery Call with InstaHear</h1>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default RedirectWithSpinner;
